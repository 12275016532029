import Typography from 'typography'

const typography = new Typography({
  baseFontSize: '16px',
  baseLineHeight: 1.75,
  scaleRatio: 2.5,
  includeNormalize: true,
  headerFontFamily: ['Georgia'],
  bodyFontFamily: ['Georgia'],
  headerWeight: 500,
  bodyWeight: 500,
  headerColor: 'rgba(0,0,0,.87)',
  bodyColor: 'rgba(0,0,0,.87)',
  overrideStyles: ({ adjustFontSizeTo, rhythm }, options, styles) => ({
    a: {
      color: 'rgb(62, 130, 196)',
      textDecoration: 'none',
    },
    'a:hover': {
      textDecoration: 'underline',
    },
    '@media (max-width: 375px)': {
      h1: {
        ...adjustFontSizeTo('1.5rem'),
      },
      h2: {
        ...adjustFontSizeTo('1.35rem'),
      },
      h3: {
        ...adjustFontSizeTo('1.25rem'),
      },
      h4: {
        ...adjustFontSizeTo('1rem'),
      },
      h5: {
        ...adjustFontSizeTo('0.8rem'),
      },
      h6: {
        ...adjustFontSizeTo('0.75rem'),
      },
    },
  }),
})

const { rhythm, scale } = typography;

export { rhythm, scale, typography as default };
