import React from 'react'
import { rhythm } from '../typography'

export default ({ kamer, bewoners }) => (
  <div
    css={{
      width: '50%',
      minWidth: 300,
      display: 'inline-block',
      verticalAlign: 'top',
      marginBottom: rhythm(1),
    }}
  >
    <h4 css={{ marginBottom: rhythm(0.25), fontSize: rhythm(0.75) }}>
      Kamer {kamer}
    </h4>
    <ul
      css={{ margin: 0, padding: 0, listStyleType: 'none', li: { margin: 0 } }}
    >
      {bewoners.map(bewoner => (
        <li key={bewoner.id}>
          {bewoner.name} ({bewoner.van} - {bewoner.tot})
        </li>
      ))}
    </ul>
  </div>
)
