import React from 'react'

import { rhythm } from '../typography'

const Header = ({ siteTitle }) => (
  <div
    css={{
      textAlign: 'center',
    }}
  >
    <div
      css={{
        margin: '0 auto',
        paddingTop: rhythm(1),
        width: 300,
        maxWidth: '100%',
      }}
    >
      <img src="/assets/logo.svg" alt="" />
      <h1
        css={{
          textTransform: 'uppercase',
          fontSize: rhythm(0.6),
          color: '#777',
        }}
      >
        Heerenhuis Double-U-Seven
      </h1>
    </div>
  </div>
)

export default Header
