import React from 'react'
import { Link } from 'gatsby'

import { rhythm } from '../typography'

export default ({ pages = [] }) => (
  <div
    css={{
      textAlign: 'center',
      marginBottom: rhythm(1),
      ul: {
        padding: 0,
        margin: '0 auto',
        listStyleType: 'none',
        fontFamily: 'Scala, serif',
        fontSize: rhythm(0.5),
        fontWeight: '400',
        width: '100%',
        maxWidth: 960,
        backgroundColor: 'rgb(27, 28, 29)',
      },
      li: { margin: 0, width: 'auto', textTransform: 'uppercase' },
      a: {
        display: 'block',
        padding: rhythm(0.25),
        color: 'rgba(255,255,255,.9)',
        backgroundColor: 'rgba(255,255,255,0)',
        ':hover': {
          color: '#fc0',
          backgroundColor: 'rgba(255,255,255,0.07)',
          textDecoration: 'none',
        },
      },
      'a.active': {
        color: 'rgba(255,255,255,1)',
        backgroundColor: 'rgba(255,255,255,0.15)',
      },
      '@media (min-width: 700px)': {
        padding: `0 0.875rem`,
        ul: {
          overflow: 'hidden',
          borderRadius: 7,
        },
        li: { display: 'inline-block', width: 100 / pages.length + '%' },
      },
    }}
  >
    <ul>
      {pages.map(({ url, name }) => (
        <li key={url}>
          <Link activeClassName="active" exact to={url}>
            {name}
          </Link>
        </li>
      ))}
    </ul>
  </div>
)
