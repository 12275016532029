import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Kamerlijst from '../components/kamerlijst'

export default ({ data }) => {
  const bewoners = data.allBewonersYaml.edges.map(edge => edge.node)
  const bewonersPerKamer = bewoners.reduce((perKamer, bewoner) => {
    if (!perKamer[bewoner.kamer]) {
      perKamer[bewoner.kamer] = []
    }
    perKamer[bewoner.kamer].push(bewoner)
    return perKamer
  }, {})
  return (
    <Layout>
      {Object.keys(bewonersPerKamer)
        .sort()
        .map(kamer => (
          <Kamerlijst
            kamer={kamer}
            bewoners={bewonersPerKamer[kamer].sort((a, b) => {
              if (a.van > b.van) {
                return 1
              }
              if (b.van > a.van) {
                return -1
              }
              return a.tot - b.tot
            })}
          />
        ))}
    </Layout>
  )
}

export const pageQuery = graphql`
  query OudBewonersPage {
    allBewonersYaml(filter: { tot: { ne: null } }) {
      edges {
        node {
          id
          name: title
          van
          tot
          kamer
        }
      }
    }
  }
`
