import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import { rhythm } from '../typography'
import Header from './header'
import Menu from './menu'

const Layout = ({ children, style }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div style={style}>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content:
                'Heerenhuis Double-U-Seven is het oudste en tevens belangrijkste studentenhuis van Enschede, gesitueerd in het karakteristieke pand aan de Wilhelminastraat. De logé zorgt ervoor dat bier en koffie altijd koud staan.',
            },
            {
              name: 'keywords',
              content: 'heerenhuis,herenhuis,studentenhuis,enschede,fubar',
            },
          ]}
        />
        <Header siteTitle={data.site.siteMetadata.title} />
        <Menu
          pages={[
            { url: '/', name: 'Home' },
            { url: '/historie', name: 'Historie' },
            { url: '/prominent', name: 'Prominent' },
            { url: '/bewoners', name: 'Bewoners' },
            { url: '/oud-bewoners', name: 'Oud-Bewoners' },
          ]}
        />
        <div
          style={{
            margin: '0 auto',
            maxWidth: 960,
            padding: `${rhythm(0.5)} ${rhythm(0.5)}`,
          }}
        >
          {children}
        </div>
      </div>
    )}
  />
)

export default Layout
